video {
    object-fit: cover;
    width: 100%;
    height: auto;
    position: fixed;
    z-index: -1;
    
}

.hero-container{
    margin-top: -4.5%;
    padding-bottom: 2%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.5);
    object-fit: contain; 
    
} 


.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    text-transform: uppercase;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    margin-left: 10%;
}

.hero-container > p {
    height: auto;
    text-transform: uppercase;
    margin-top: 8px;
    color: #fff;
    text-align: left;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 10%;
}

.hero-container > p > span {
    color: orange;
}

.hero-btns {
    /* background-color: #fff; */
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
    margin-left: 10%;
}

.hero-btns .btn{
    margin: 6px;;
}

@media only screen and (max-width:768px) {

    .hero-container > h1 {
        font-size: 60px;
        margin: 0 5%;
        text-align: center;
    }
    
    .hero-container > p {
        text-align: center;
        font-size: 20px;
        margin: 0 5%;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    
    .btn {
        width: 100%;
    }

}