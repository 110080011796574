:root{
    --orange: rgba(249,155,50,1);
    --yellow: rgba(249, 217, 50, 1);
    --gradient: to right,rgba(249,155,50,1), rgba(249, 217, 50, 1);
    --darkblue: #0d1e25;
    --darker: #081216;
}
*{
    box-sizing: border-box;
}
hr{
    border: solid 1px white;
    width: 100%;
}

.footer-container{
    color: white;
    background-color: var(--darker);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
}

.row.footer-subscription{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 24px 0;
    color: white;
}

.footer-subscription-form{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 9%;
}

.footer-subscription-heading{
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 20px;
}
.input-areas{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 8%;
}
.footer-input{
    
    color: white;
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border-bottom: 1px solid #fff;
    background: transparent;
}

.footer-links{
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper{
    display: flex;
    padding: 0 8%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

}

.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 16px;
    height: 100%;
    text-align: left;
    width: auto;
    box-sizing: border-box;
}

.footer-link-items h2{
    margin-bottom: 16px;
}

.footer-link-items > h2{
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover{
    color: var(--orange);
    transition: 0.3s ease-out;
}

.footer-email-form h2{
    margin-bottom: 32px;
}

.footer-input::placeholder{
    color: #b1b1b1;
}

.social-icon-link{
    color: #fff;
    font-size: 30px;
}

.social-icon-link.facebook:hover{
    color: rgb(45, 77, 236);
    transition: 0.35s ease-in;
}

.social-icon-link.instagram:hover{
    color: rgb(235, 50, 127);
    transition: 0.35s ease-in;
}

.social-icon-link.web:hover{
    color: rgb(65, 251, 137);
    transition: 0.35s ease-in;
}

.social-media{
    width: 100%;
}

.social-media-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1600px;
    margin: 40px auto 0 auto;
    
}

.footer-logo{
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo a{
    text-decoration: none;
}

.footer-logo small{
    margin-left: 10px;
    font-size: 15px;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}
