:root{
  --darkblue: #0d1e25;
}

.preturi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: var(--darkblue);
}


.cards {
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 3%;
}

.preturi > h1 {
  margin-top: 3%;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 800;
  letter-spacing: 3px;
}

.cards__container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  max-width: 100vw;
  width: 100%;
  height: auto; 
}

.cards__wrapper {
  position: relative;
  margin: 0;
}

.cards__items {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  margin: 5% 0;
  
}

.middle__card, .left__card, .right__card {
  background-color: rgb(236, 225, 210);
  width: 400px;
  display: flex;
  flex: 1;
  margin: 0 5rem;
  border-radius: 10px;
  box-shadow: 0 5px 30px rgba(255 , 255, 255, 0.3);
  -webkit-filter: drop-shadow(0 5px 30px rgba(255 , 255, 255, 0.5));
  filter: drop-shadow(0 5px 30px rgba(255 , 255, 255, 0.5));
  transition: all 0.4s ease-out;
}

.middle__card:hover { 
  transform:translateY(-50px);
}

.middle__card:hover .price{ 
  color: rgb(172, 146, 0);
  transition: all 0.4s ease-out;
  
}

.left__card:hover{
  transform: translateX(-50px) translateY(-25px);
}

.left__card:hover .price{ 
  color: rgb(187, 124, 41);
  transition: all 0.4s ease-out;
}

.right__card:hover{
  transform: translateX(50px) translateY(-25px);
}

.right__card:hover .price{ 
  color: rgb(204, 195, 195);
  transition: all 0.4s ease-out;
}

.middle__card{
  height: auto;
}

.left__card, .right__card{
  height: auto;
}

.cards__item__link Link{
  background: none;
}
.cards__item__link {
  border-radius: 10px;
  text-decoration: none;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  
}

.cards__title{
  text-align: center;
  text-transform: uppercase;
  
}
.cards__title .bronze {
  color: #000;
  font-weight: 800;
  font-size: 50px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.cards__title .gold {
  color: #000;
  font-weight: 800;
  font-size: 60px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards__title .silver {
  color: #000;
  font-weight: 800;
  font-size: 55px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.cards__price{
  margin: 5% 0;
  text-transform: uppercase;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards__price .price{
  color: #000;
  font-weight: 700;
  font-size: 60px;
  text-align: center;
}

.cards__price > h1 > sup, .cards__price > h1 > small{
  font-size: 18px;
  color: #000;
  font-weight: 800;
}

.cards__price > h1 > sup{
  top: -50px;
  left: -10px;
}

.cards__item__info {
  padding: 20px 30px 30px;
  color: #000;
  text-align: center;
  height: 200px;
  padding-bottom: 100px;
}

.cards__item__text {

  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    background: black;
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .cards {
    width: 100%;
  }
}