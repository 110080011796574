:root{
    --orange: rgba(249,155,50,1);
    --yellow: rgba(249, 217, 50, 1);
    --gradient: to right,rgba(249,155,50,1), rgba(249, 217, 50, 1);
    --darkblue: #0d1e25;
}

.contact_area{
    height: 100vh;
    background: var(--darkblue);
    color: white;
}

.contact_area > hr{
    height: 10px;
    border: 1;
    box-shadow: inset 0 9px 9px -3px rgba(249, 217, 50, 0.8);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.contact_title{
    padding: 3% 0 0 0;
    text-align: center;
    margin-bottom: 2%;
}

.contact_title > h1{
    font-size: 60px;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.contact_details > hr.style-three{
    height: 3px;
    border-style: solid;
    border-color: white;
    border-width: 4px 0 0 0;
    border-radius: 20px;
    width: 55%;
}

hr.style-three{
    margin-top: 3%;
    width:100%;
    height: 3px;
    border-style: solid;
    border-color: white;
    border-width: 4px 0 0 0;
    border-radius: 20px;
}

.contact_details{
    padding-left: 10%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.contact_details > h2{
   font-size: 40px;
}

.contact_details a{
    text-decoration: none;
    color: white;
}

.contact_area .middle{
    margin: 0;
    padding: 0;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.contact_form{
    margin: 0;
    padding: 0;
    padding-right: 3%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    
}

.contact_form > form > h2{
    font-size: 40px;
    text-align: right;
}

.contact_form > form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: space-evenly;
}

.contact_form > form > label{
    font-size: 20px;
    text-transform: uppercase;
    text-align: right;
}

textarea, input[type=text]{
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */
    border: none;
    border-bottom: 3px solid #fff; /* Gray border */
    background: transparent;
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    color: white;

}

input[type=submit]{
    
    margin-top: 3%;
    width: 100px;
    background: none;
    border: none;
    height: 30px;
    border-top:3px solid var(--orange);
    border-bottom: 3px solid #fff;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.8s ease-out;
}

input[type=submit]:hover{
    color: #000;
    border: none;
    background: var(--orange);
    height: 30px;
    
}


