:root{
    --primary: #fff;

}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.btn--primary {
    background-color: var(--primary);
    color: #000;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.25s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;

}

.btn--medium:hover,
.btn--large:hover {
    background-color: orange;
    color: #fff;
    transition: all 0.25s ease-out;
}

