:root{
    --darkblue: #0d1e25;
}
.despre{
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: var(--darkblue);
    
}

.image-area{
    width: 45%;
    z-index: 1;
    position: relative;
    left: 150px;
}

.text-area{ 
    margin: 0 2%;
    background-color: rgba(255, 255, 255, 0.7);
    position: relative;
    z-index: 2;
    right: 50px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    border-radius: 10px;
}

.text-area > h2, .text-area > p {
    color:black;
    font-weight: 700;
    margin-left: 15px;
}

.text-area > p{
    line-height: 2rem;
}