*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --white: #fff;
  --black: #000;
  --orange: orange;
  --lorange: rgb(252, 218, 157);
}
.navbar {
    background: transparent;
    /* background: rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0 5px 30px rgba(0 , 0, 0, 0.5); */
    /* -webkit-filter: drop-shadow(0 5px 30px rgba(0 , 0, 0, 0.5)); */
    /* filter: drop-shadow(0 5px 30px rgba(0 , 0, 0, 0.5)); */
    padding: 0;
    margin: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .navbar-container > .empty-space{
    width: 40%;
    background-color: black;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 100%;
  }
  
  .navbar-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: var(--white);
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 0.1rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid var(--lorange);
    transition: all 0.2s ease-out;
    color: rgb(252, 218, 157);
    font-weight: 800;
  }
  
  .fa-bars {
    color: var(--black);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .btn-preturi {
    background-color: transparent;
    color: var(--white);
    padding: 8px 20px;
    border: 1px solid var(--white);
    transition: all 0.25s ease-out;
    text-transform: uppercase;
    font-size: 20px;
  }

  .btn-preturi:hover{
    background-color: orange;
    color: #fff;
    border: none;
    transition: all 0.25s ease-out;
  }


  @media screen and (max-width: 960px) {
    
    .navbar {
        background-color: var(--white);
        width: 100%;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: var(--orange);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      color: var(--black);
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: var(--black);
      color: var(--white);
      border-radius: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: var(--black);
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: var(--black);
      padding: 14px 20px;
      border: 1px solid var(--black);
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: var(--black);
      color: var(--white);
      transition: 250ms;
    }
  }

  @media screen and (max-width: 576px) {
    
    .navbar {
        background-color: var(--white);
        width:100%;
    }
    
    .btn-preturi{
      display: none;
    }
  }